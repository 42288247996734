// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';

/**
 * Init foundation
 */
$(document).foundation();
/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();

  $('.successes-infographic-slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    infinite: true,
    asNavFor: '.successes-content-slider',
  });
  $('.successes-content-slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    asNavFor: '.successes-infographic-slider',
    fade: true,
    dots: false,
    centerMode: true,
    focusOnSelect: true,
    infinite: true,
    prevArrow: $('.successes-content-slider__prev-arrow'),
    nextArrow: $('.successes-content-slider__next-arrow'),
  });
});

$('.video-section__play').fancybox({
  // Options will go here
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
  $('#load-more').click(function () {
    // $('#load-more').addClass('position');
    $('#load-more').fadeOut(function () {
      $('#load-more')
        .text($('#load-more').text() == 'Load More' ? 'Collapse' : 'Load More')
        .fadeIn();
    });
    $('#load-more').toggleClass('collapsed  position');
  });

  if ($(window).width() < 600) {
    $('.tabs-title a').click(function () {
      $('html, body').animate(
        {
          scrollTop:
            $('.testimonials').position().top - $('.header').outerHeight(),
        },
        1000
      );
      console.log('test');
    });
  }
});

$('.header').on('click', 'a[href*="#"]:not([href="#"])', function (event) {
  var $this = $(event.target);
  var href = $this.attr('href');
  var id = href && href.indexOf('#') >= 0 ? '#' + href.split('#')[1] : null;
  $('html, body').animate(
    {
      scrollTop: $(id).position().top - 100,
    },
    1000
  );
});
/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here

  //----------------------fixed-menu----------
  var $menu = $('.header');
  $(window).scroll(function () {
    if ($(this).scrollTop() > 1 && $menu.hasClass('default')) {
      $menu.removeClass('default').addClass('white-bg');
    } else if ($(this).scrollTop() <= 1 && $menu.hasClass('white-bg')) {
      $menu.removeClass('white-bg').addClass('default');
    }
  });
});

/**
 * Set dynamic for hidden content of home template
 */
const homeReadMoreBtn = document.querySelector('.text-block__button');
const homeHiddenBottomContent = document.querySelector('.text-block__hidden');
if (homeReadMoreBtn && homeHiddenBottomContent) {
  homeReadMoreBtn.addEventListener('click', (event) => {
    event.preventDefault();
    homeHiddenBottomContent.classList.toggle('visable');
    homeReadMoreBtn.classList.toggle('active');
  });
}



$('#cat').change(function () {
  let dropdown = document.getElementById('cat');
  if (dropdown.options[dropdown.selectedIndex].value) {
    let category = dropdown.options[dropdown.selectedIndex].value;
    filterPosts(category);
  }
});

$('.js-faq-pagination .page-numbers').click(function (e) {
  $('html, body').animate(
    {
      scrollTop: $('html').offset().top + 100,
    },
    500
  );
  e.preventDefault();
  filterPosts('-1', $(this).attr('href'));
});

function filterPosts(category = '', paged = 1) {
  let data = {
    action: 'filter_posts',
    category: category,
    paged: paged,
    // eslint-disable-next-line no-undef
    nonce: ajax_object.nonce,
  };
  $('.faqs-list-js').fadeOut();
  // eslint-disable-next-line no-undef
  $.post(ajax_object.ajax_url, data, function (response) {
    if (response) {
      $('.faqs-list-js').html(response);
      $('.faqs-list-js').addClass('ajax-js');
      $('.ajax-js .archive-pagination .page-numbers').click(function (e) {
        $('html, body').animate(
          {
            scrollTop: $('html').offset().top + 100,
          },
          500
        );
        e.preventDefault();
        filterPosts(category, $(this).attr('href'));
      });
      $('.faqs-list-js').fadeIn();
    }
  });
}




